define("discourse/plugins/discourse-bcc/discourse/components/bcc-checkbox", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "",
    bccAvailable() {
      return this.currentUser.staff && this.creatingPrivateMessage && ((this.targetRecipients || "").split(",").filter(Boolean).length > 1 || this.targetGroups);
    }
  }, [["method", "bccAvailable", [(0, _decorators.default)("creatingPrivateMessage", "targetRecipients", "targetGroups")]]]));
});