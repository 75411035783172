define("discourse/plugins/discourse-bcc/discourse/initializers/setup-bcc", ["exports", "discourse/adapters/rest", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _rest, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "setup-bcc",
    after: "inject-objects",
    initialize(container) {
      let composer = container.factoryFor("model:composer");
      if (composer) {
        composer.class.serializeOnCreate("use_bcc");
        composer.class.serializeToDraft("use_bcc");
      }
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        api.modifyClass("adapter:post", {
          pluginId: "discourse-bcc",
          createRecord(store, type, args) {
            if (type === "post" && args.use_bcc) {
              return (0, _ajax.ajax)("/posts/bcc", {
                method: "POST",
                data: args
              }).then(json => {
                return new _rest.Result(json.post, json);
              });
            } else {
              delete args.use_bcc;
              return this._super(store, type, args);
            }
          }
        });
      });
    }
  };
});