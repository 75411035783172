define("discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if bccAvailable}}
    <div class="bcc-checkbox">
      <label>
        <Input @type="checkbox" @checked={{checked}} />
        {{i18n "discourse_bcc.use_bcc"}}
      </label>
    </div>
  {{/if}}
  */
  {
    "id": "M2MAlmDN",
    "block": "[[[41,[30,0,[\"bccAvailable\"]],[[[1,\"  \"],[10,0],[14,0,\"bcc-checkbox\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n      \"],[1,[28,[35,2],[\"discourse_bcc.use_bcc\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `bccAvailable` property path was used in the `discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.bccAvailable}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `checked` property path was used in the `discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.checked}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});